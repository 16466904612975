import * as React from 'react';
// import CategoryLayout from '../components/CategoryLayout';
import FeaturedFlash from '../components/layout/FeaturedFlash';

// import OtherPosts from '../components/OtherPosts';
import FlashCards from '../components/FlashCards';
import {
  // usePulsoTecnicoPosts,
  useFlashRentaFijaPosts,
  useOtrosArticulosPosts,
} from '../operations/queries';
// import getCategoryColor from '../utils/getCategoryColor';
import RecentPosts from '../components/layout/RecentPosts';

import "../theme/category.scss"
import HeaderLayout from '../components/layout/HeaderLayout';
import CustomFooter from '../components/CustomFooter';
import GdprCookies from '../components/GdprCookies';
import ModalSuscribe from '../components/layout/ModalSuscribre';
import SearchIcon from '@material-ui/icons/Search';

import Featured from '../components/layout/Featured';
import useIsClient from '../utils/isClient';
import { useRentaFijaPosts } from '../operations/queries/rentaFijaPosts';

export default function Tecnico(): JSX.Element {
  const otherPosts = useOtrosArticulosPosts();
  const flashRentaFijaPosts = useFlashRentaFijaPosts();
  const rentaFijaPosts = useRentaFijaPosts();

  // const color = getCategoryColor('rentaFija');

  //functions modal suscribe
  const [open, setOpen] = React.useState(false);
  function openSuscribe(){
    setOpen(true);
  }
  function closeSuscribe(){
    setOpen(false);
  }
  const isClient = useIsClient();
  if(isClient){
  return (
    // <CategoryLayout title="Renta Fija y Cambios">
    //   <FeaturedFlash
    //     header="Flash Destacado"
    //     post={flashRentaFijaPosts[0]}
    //     barColor={color}
    //   />
    //   <FlashCards header="Flash Renta Fija" posts={flashRentaFijaPosts} />
    //   <OtherPosts header="Otros Artículos" posts={otherPosts} />

    // </CategoryLayout>

    <>
      <HeaderLayout mb={true} openModal={openSuscribe}/>

      <div className="container">
        
        <div className='header--section'>
          <h2 className='title--section'>Renta Fija</h2>
          <div className='search--section'>
            <form action="/buscar">
              <input type="text" name='q' />
              <SearchIcon className='icon--search'/>  
            </form>
          </div>
        </div>

        <Featured data={rentaFijaPosts[0]}/>
        <FeaturedFlash post={flashRentaFijaPosts[0]}/>
        <FlashCards header="Flash Renta Fija" posts={flashRentaFijaPosts} />

        <RecentPosts posts={otherPosts} title="Otros artículos" link={true}/>
      </div>
      <CustomFooter openModal={openSuscribe}/>
      <GdprCookies />
      {
        open && <ModalSuscribe closeModal={closeSuscribe}/>
      }
    </>
  );
  }else{
    return(<></>)
  }
}
