import { graphql, useStaticQuery } from 'gatsby';
import { ContentfulRentaFija } from '../../../types/graphql-types';
import parseDate from '../../utils/parseDate';
import { PostPreview as PostsPreviewProps } from '../../models/PostPreview';


export const useRentaFijaPosts = (): PostsPreviewProps[] => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulRentaFija(
        sort: { fields: createdAt, order: DESC }
        limit: 1
      ) {
        totalCount
        edges {
          node {
            ...rentaFijaPreview
          }
        }
      }
    }
  `);

  const rentaFijaPosts = data.allContentfulRentaFija.edges.map(
    (edge: { node: ContentfulRentaFija }) => {
      const { id, titulo, resumen, slug, createdAt, autor,heroImage } = edge.node;
      const categorySlug = 'flash';
      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Renta Fija',
        slug: `/renta-fija/${slug}`,
        date: parseDate(createdAt),
        categorySlug,
        author: autor?.nombre,
        fluid: heroImage,

      };
    }
  );

  return rentaFijaPosts;
};
